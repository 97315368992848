// Purpose: General routes that don’t fit into other categories.
import { Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import Home from "../screens/Home";
import Market from "../screens/Market";
import Contact from "../screens/Contact";
import Notifications from "../screens/Notifications";
import Activity from "../screens/Activity";
import Exchange from "../screens/Exchange";
import PageList from "../screens/PageList";

const GeneralRoutes = () => (
  <Routes>
    <Route path="/" element={<Page><Home /></Page>} />
    <Route path="market" element={<Page><Market /></Page>} />
    <Route path="contact" element={<Page><Contact /></Page>} />
    <Route path="notifications" element={<Page><Notifications /></Page>} />
    <Route path="activity" element={<Page><Activity /></Page>} />
    <Route path="exchange" element={<Page headerWide footerHide><Exchange /></Page>} />
    <Route path="/pagelist" element={<PageList />} />
  </Routes>
);

export default GeneralRoutes;

