// Purpose: Handles cryptocurrency-related pages and learning.
import { Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import LearnCrypto from "../screens/LearnCrypto";
import LearnCryptoDetails from "../screens/LearnCryptoDetails";
import BuyCrypto from "../screens/BuyCrypto";
import SellCrypto from "../screens/SellCrypto";
import DepositFiat from "../screens/DepositFiat";

const CryptoRoutes = () => (
  <Routes>
    <Route path="learn-crypto" element={<Page><LearnCrypto /></Page>} />
    <Route path="learn-crypto-details" element={<Page><LearnCryptoDetails /></Page>} />
    <Route path="buy-crypto" element={<Page><BuyCrypto /></Page>} />
    <Route path="sell-crypto" element={<Page><SellCrypto /></Page>} />
    <Route path="deposit-fiat" element={<Page><DepositFiat /></Page>} />
  </Routes>
);

export default CryptoRoutes;
