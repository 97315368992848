// Purpose: Handles authentication-related routes.
import { Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp";
import ForgotPassword from "../screens/ForgotPassword";
import ChangePassword from "../screens/ChangePassword";
import TwoFa from "../screens/TwoFa";

const AuthRoutes = () => (
  <Routes>
    <Route path="sign-in" element={<Page headerHide footerHide><SignIn /></Page>} />
    <Route path="sign-up" element={<Page headerHide footerHide><SignUp /></Page>} />
    <Route path="forgot-password" element={<Page headerHide footerHide><ForgotPassword /></Page>} />
    <Route path="change-password" element={<Page><ChangePassword /></Page>} />
    <Route path="2fa" element={<Page><TwoFa /></Page>} />
  </Routes>
);

export default AuthRoutes;
