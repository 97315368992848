import axios from 'axios';

// Create axios instance with base URL and timeout
const apiClient = axios.create({
  baseURL: 'https://your-secure-backend.com/api',
  timeout: 10000,  // Set request timeout to 10 seconds
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor: Attach JWT token
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Optional: Add CSRF Token if available
    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle token expiration
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Token expired, attempt to refresh token and retry the request
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      const newToken = await refreshAccessToken(refreshToken);
      localStorage.setItem('token', newToken);
      apiClient.defaults.headers.Authorization = `Bearer ${newToken}`;
      return apiClient(originalRequest);  // Retry the original request
    }

    return Promise.reject(error);
  }
);

async function refreshAccessToken(refreshToken) {
  const response = await axios.post('https://your-secure-backend.com/api/refresh-token', { refreshToken });
  return response.data.accessToken;
}

export default apiClient;
