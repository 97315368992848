// Purpose: Combines all the grouped routes into one central file.
import GeneralRoutes from "./GeneralRoutes";
import AuthRoutes from "./AuthRoutes";
import CryptoRoutes from "./CryptoRoutes";
import WalletRoutes from "./WalletRoutes";
import UserRoutes from "./UserRoutes";

const AppRoutes = () => (
    <>
      {/* General routes (Home, Market, etc.) */}
      <GeneralRoutes />
  
      {/* Authentication-related routes */}
      <AuthRoutes />
  
      {/* Crypto-related routes */}
      <CryptoRoutes />
  
      {/* Wallet-related routes */}
      <WalletRoutes />
  
      {/* User-related routes */}
      <UserRoutes />
    </>
  );
  
  export default AppRoutes;
