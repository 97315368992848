// Purpose: Handles routes related to user profiles and settings.
import { Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import ProfileInfo from "../screens/ProfileInfo";
import Referrals from "../screens/Referrals";
import ApiKeys from "../screens/ApiKeys";
import SessionsAndLoginHistory from "../screens/SessionsAndLoginHistory";

const UserRoutes = () => (
  <Routes>
    <Route path="profile-info" element={<Page><ProfileInfo /></Page>} />
    <Route path="referrals" element={<Page><Referrals /></Page>} />
    <Route path="api-keys" element={<Page><ApiKeys /></Page>} />
    <Route path="sessions-and-login-history" element={<Page><SessionsAndLoginHistory /></Page>} />
  </Routes>
);

export default UserRoutes;
