// Purpose: Manages routes related to wallet and financial information.
import { Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import WalletOverview from "../screens/WalletOverview";
import WalletOverviewDetails from "../screens/WalletOverviewDetails";
import WalletMargin from "../screens/WalletMargin";
import FiatAndSpot from "../screens/FiatAndSpot";

const WalletRoutes = () => (
  <Routes>
    <Route path="wallet-overview" element={<Page headerWide footerHide><WalletOverview /></Page>} />
    <Route path="wallet-overview/:id" element={<Page headerWide footerHide><WalletOverviewDetails /></Page>} />
    <Route path="wallet-margin" element={<Page headerWide footerHide><WalletMargin /></Page>} />
    <Route path="fiat-and-spot" element={<Page headerWide footerHide><FiatAndSpot /></Page>} />
  </Routes>
);

export default WalletRoutes;
