// App.js
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./styles/app.sass";

function App() {
  return <AppRoutes />;
}

export default App;
