import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Trend.module.sass";
import { Link } from "react-router-dom";
import Dropdown from "../../../components/Dropdown";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import {
  connectToCryptoWebSocket,
  disconnectFromCryptoWebSocket,
} from "../../../services/cryptoService";

const navigation = [
  "All",
  "DeFi",
  "Innovation",
  "POS",
  "NFT",
  "POW",
  "Storage",
];

// Initialize items with priceHistory for each item
const initialItems = [
  {
    title: "Bitcoin",
    currency: "BTC",
    price: null,
    change: null,
    priceHistory: [], // Ensure priceHistory is initialized
    image: "/images/content/currency/bitcoin.svg",
    url: "/exchange",
    symbol: "BTCUSDT",
  },
  {
    title: "Ethereum",
    currency: "ETH",
    price: null,
    change: null,
    priceHistory: [],
    image: "/images/content/currency/ethereum.svg",
    url: "/exchange",
    symbol: "ETHUSDT",
  },
  {
    title: "Bitcoin Cash",
    currency: "BCH",
    price: null,
    change: null,
    priceHistory: [],
    image: "/images/content/currency/bitcoin-cash.svg",
    url: "/exchange",
    symbol: "BCHUSDT",
  },
  {
    title: "Ripple",
    currency: "XRP",
    price: null,
    change: null,
    priceHistory: [],
    image: "/images/content/currency/ripple.svg",
    url: "/exchange",
    symbol: "XRPUSDT",
  },
  {
    title: "Chainlink",
    currency: "LINK",
    price: null,
    change: null,
    priceHistory: [],
    image: "/images/content/currency/chainlink.svg",
    url: "/exchange",
    symbol: "LINKUSDT",
  },
];

const Trend = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    // Connect to the WebSocket
    connectToCryptoWebSocket((data) => {
      // Update the state with the new price and change for the corresponding symbol
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.symbol === data.s) {
            const newPrice = parseFloat(data.c); // Last price
            const newChange = parseFloat(data.P); // Price change percent

            // Create a new price history array with the new data point
            const newPriceHistory = [
              ...(item.priceHistory || []), // Ensure priceHistory is an array
              {
                time: new Date().toLocaleTimeString(),
                price: newPrice,
              },
            ];

            // Limit the price history to the last 50 data points
            if (newPriceHistory.length > 50) {
              newPriceHistory.shift(); // Remove the oldest entry
            }

            return {
              ...item,
              price: newPrice,
              change: newChange,
              priceHistory: newPriceHistory,
            };
          } else {
            // Ensure priceHistory is defined for items not updated
            return {
              ...item,
              priceHistory: item.priceHistory || [],
            };
          }
        })
      );
    });

    // Cleanup on unmount
    return () => {
      disconnectFromCryptoWebSocket();
    };
  }, []);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.line}>
          <h2 className={cn("h2", styles.title)}>Market Trend</h2>
          <Link className={cn("button-stroke", styles.button)} to="/market">
            View more
          </Link>
        </div>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={sorting}
            setValue={setSorting}
            options={navigation}
          />
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>24h Change</div>
            <div className={styles.col}>Chart</div>
            <div className={styles.col}>Trade</div>
          </div>
          {items.map((x, index) => {
            const price =
              x.price !== null
                ? `$${x.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })}`
                : "Loading...";
            const change =
              x.change !== null ? `${x.change.toFixed(2)}%` : "Loading...";
            const isNegative = x.change !== null && x.change < 0;

            return (
              <Link className={styles.row} to={x.url} key={index}>
                <div className={styles.col}>{index + 1}</div>
                <div className={styles.col}>
                  <div className={styles.item}>
                    <div className={styles.icon}>
                      <img src={x.image} alt="Currency" />
                    </div>
                    <div className={styles.details}>
                      <span className={styles.subtitle}>{x.title}</span>
                      <span className={styles.currency}>{x.currency}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>{price}</div>
                <div className={styles.col}>
                  {x.change !== null && (
                    <div
                      className={isNegative ? styles.negative : styles.positive}
                    >
                      {change}
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  <div className={styles.chart}>
                    {x.priceHistory && x.priceHistory.length > 1 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={x.priceHistory}
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id={`colorPrice${index}`}
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#45B36B"
                                stopOpacity={0.6}
                              />
                              <stop
                                offset="95%"
                                stopColor="#45B36B"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <Area
                            type="monotone"
                            dataKey="price"
                            stroke="#58BD7D"
                            fillOpacity={1}
                            fill={`url(#colorPrice${index})`}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className={styles.loadingChart}>Loading chart...</div>
                    )}
                  </div>
                </div>
                <div className={styles.col}>
                  <button
                    className={cn(
                      "button-stroke button-small",
                      styles.button
                    )}
                  >
                    Trade
                  </button>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Trend;
