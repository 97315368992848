// Cards.js

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Cards.module.sass';
import { connectToCryptoWebSocket, disconnectFromCryptoWebSocket } from '../../../../services/cryptoService';

const Cards = ({ className }) => {
  // State to hold live prices for each pair
  const [prices, setPrices] = useState([
    { title: 'BTC/USDT', price: 'Loading...', change: 'Loading...', image: 'images/content/currency/bitcoin.svg', symbol: 'BTCUSDT' },
    { title: 'ETH/USDT', price: 'Loading...', change: 'Loading...', image: 'images/content/currency/ethereum.svg', symbol: 'ETHUSDT' },
    { title: 'BNB/USDT', price: 'Loading...', change: 'Loading...', image: 'images/content/currency/bnb.svg', symbol: 'BNBUSDT' },
    { title: 'ADA/USDT', price: 'Loading...', change: 'Loading...', image: 'images/content/currency/cardano.svg', symbol: 'ADAUSDT' },
  ]);

  useEffect(() => {
    // Connect to the WebSocket and set up the live price updates
    connectToCryptoWebSocket((data) => {
      // Update the state with the new price and change for the corresponding symbol
      setPrices((prevPrices) =>
        prevPrices.map((pair) =>
          pair.symbol === data.s // 's' is the symbol property from Binance data
            ? {
                ...pair,
                price: data.c,    // 'c' is the last price
                change: data.P,   // 'P' is the price change percent
              }
            : pair
        )
      );
    });

    // Cleanup WebSocket connection on component unmount
    return () => {
      disconnectFromCryptoWebSocket();
    };
  }, []);

  return (
    <div className={cn(className, styles.cards)}>
      {prices.map((x, index) => {
        const price = parseFloat(x.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 8 });
        const change = parseFloat(x.change).toFixed(2);
        const isNegative = parseFloat(x.change) < 0;
        return (
          <Link className={styles.card} key={index} to="/exchange">
            <div className={styles.icon}>
              <img src={x.image} alt={x.title} />
            </div>
            <div className={styles.details}>
              <div className={styles.line}>
                <div className={styles.title}>{x.title}</div>
                <div className={isNegative ? styles.negative : styles.positive}>
                  {change}%
                </div>
              </div>
              <div className={styles.price}>{price}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Cards;
