import apiClient from './apiClient'; // Assuming apiClient is already configured for API calls

// services/cryptoService.js

// WebSocket connection object
let ws = null;

/**
 * Connect to WebSocket for live crypto price updates
 * @param {Function} onMessage - Callback function to handle messages from the WebSocket
 */
export const connectToCryptoWebSocket = (onMessage) => {
  ws = new WebSocket('wss://www.1payt.com/ws/'); // Use the correct WebSocket server URL

  ws.onopen = () => {
    console.log('WebSocket connected');
    // No need to send any messages unless the server expects it
  };

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    onMessage(data); // Trigger the callback with live price updates
  };

  ws.onclose = () => {
    console.log('WebSocket disconnected');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };
};

/**
 * Disconnect from WebSocket
 */
export const disconnectFromCryptoWebSocket = () => {
  if (ws) {
    ws.close();
  }
};

/**
 * Fetch the current prices of all supported crypto pairs
 */
export const getCryptoPrices = async () => {
  try {
    const response = await apiClient.get('/crypto/prices');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch historical price data for a specific crypto pair
 * @param {string} symbol - The symbol of the crypto pair (e.g., BTCUSDT)
 * @param {string} interval - Time interval (e.g., '1m', '1h', '1d')
 */
export const getHistoricalData = async (symbol, interval) => {
  try {
    const response = await apiClient.get(`/crypto/historical/${symbol}?interval=${interval}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch market stats for a specific crypto pair
 * @param {string} symbol - The symbol of the crypto pair (e.g., BTCUSDT)
 */
export const getMarketStats = async (symbol) => {
  try {
    const response = await apiClient.get(`/crypto/market-stats/${symbol}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch top crypto pairs by market cap
 */
export const getTopCryptoByMarketCap = async () => {
  try {
    const response = await apiClient.get('/crypto/top-market-cap');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch 24-hour price change statistics for a specific crypto pair
 * @param {string} symbol - The symbol of the crypto pair (e.g., BTCUSDT)
 */
export const get24hPriceChange = async (symbol) => {
  try {
    const response = await apiClient.get(`/crypto/24h-price-change/${symbol}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch order book depth for a specific crypto pair
 * @param {string} symbol - The symbol of the crypto pair (e.g., BTCUSDT)
 * @param {number} limit - The number of orders to return (default is 100)
 */
export const getOrderBookDepth = async (symbol, limit = 100) => {
  try {
    const response = await apiClient.get(`/crypto/order-book/${symbol}?limit=${limit}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch recent trades for a specific crypto pair
 * @param {string} symbol - The symbol of the crypto pair (e.g., BTCUSDT)
 */
export const getRecentTrades = async (symbol) => {
  try {
    const response = await apiClient.get(`/crypto/recent-trades/${symbol}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

/**
 * Fetch the user's account balance
 */
export const getAccountBalance = async () => {
  try {
    const response = await apiClient.get('/crypto/account-balance');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};
